type IError = {
  children: React.ReactNode;
};

export default function Error({ children }: IError) {
  return (
    <div className="p-4 rounded-md bg-red-50">
      <div className="flex">
        <span className="text-sm font-normal text-center text-red-800">{children}</span>
      </div>
    </div>
  );
}
