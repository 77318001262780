import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useRef, useState } from "react";

import Error from "@/components/site/Error";
import { LockClosedIcon } from "@heroicons/react/outline";
import errors from "@/utils/errors";
import { trpc } from "@/utils/trpc";
import { useForm } from "react-hook-form";
import { useModal } from "@/context/Modal";

// Metamask "manmademammal 2" address
const RECIPIENT_ADDRESS = "0xB9B5c9f2fD2D5F2dc0E88aB6Cc453913ab8B0b1E";

export default function EthModal() {
  const { ethModal } = useModal();
  const mutation = trpc.useMutation(["eth.createTxn"]);
  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      recipient: RECIPIENT_ADDRESS,
      message: "Hello Ethereum!",
      amount: "0.01",
    },
  });
  const [txnError, setTxnError] = useState("");
  let completeButtonRef = useRef(null);

  const resetState = () => {
    // Add a delay to let the modal disappear before clearing the form
    setTimeout(() => {
      reset();
      setTxnError("");
    }, 500);
  };

  type IOnSubmit = {
    recipient: string;
    message: string;
    amount: string;
  };

  const onSubmit = async (data: IOnSubmit) => {
    setTxnError("");

    if (!data.recipient) {
      setTxnError("Invalid recipient address.");
      return;
    }

    if (!data.amount) {
      setTxnError("Invalid amount.");
      return;
    }

    if (!data.message) {
      setTxnError("Invalid message.");
      return;
    }

    const response = await mutation.mutateAsync({
      recipient: data.recipient,
      amountInEth: Number(data.amount),
    });

    if (!response.error) {
      ethModal.close();
      resetState();
      return;
    }

    switch (response.error) {
      case errors.INSUFFICIENT_FUNDS:
        setTxnError("Insufficient funds.");
        break;
      case errors.UNKNOWN:
        setTxnError("Something went wrong.");
        break;
      default: // no-op
    }
  };

  return (
    <Transition.Root show={ethModal.isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={() => {
          ethModal.close();
          resetState();
        }}
        initialFocus={completeButtonRef}
      >
        <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-md sm:w-full sm:p-6">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="grid grid-cols-12 gap-y-6 gap-x-4">
                  <div className="col-span-full">
                    <label
                      htmlFor="recipient-address"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Recipient Address
                    </label>

                    <div className="mt-1">
                      <input
                        type="text"
                        id="recipient-address"
                        className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        {...register("recipient", { required: true })}
                      />
                    </div>
                  </div>

                  <div className="col-span-8 sm:col-span-9">
                    <label htmlFor="message" className="block text-sm font-medium text-gray-700">
                      Message / Payload
                    </label>

                    <div className="mt-1">
                      <input
                        type="text"
                        id="message"
                        className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        {...register("message", { required: true })}
                      />
                    </div>
                  </div>

                  <div className="col-span-4 sm:col-span-3">
                    <label htmlFor="amount" className="block text-sm font-medium text-gray-700">
                      Amount
                    </label>

                    <div className="mt-1">
                      <input
                        type="text"
                        id="amount"
                        className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        {...register("amount", { required: true })}
                      />
                    </div>
                  </div>
                </div>

                {txnError ? (
                  <div className="mt-4">
                    <Error>{txnError}</Error>
                  </div>
                ) : null}

                <button
                  type="submit"
                  className="w-full px-4 py-2 mt-6 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Send
                </button>

                <p className="flex justify-center mt-6 text-sm font-medium text-gray-500">
                  <LockClosedIcon className="w-5 h-5 text-gray-400 mr-1.5" aria-hidden="true" />
                  We never store your payment information.
                </p>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
