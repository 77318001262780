import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useRef, useState } from "react";

import Error from "@/components/site/Error";
import { LockClosedIcon } from "@heroicons/react/outline";
import errors from "@/utils/errors";
import { trpc } from "@/utils/trpc";
import { useForm } from "react-hook-form";
import { useModal } from "@/context/Modal";

export default function PaymentModal() {
  const { paymentModal } = useModal();
  const createBilling = trpc.useMutation(["billing.create"]);
  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      number: "4242 4242 4242 4242",
      expirationDate: "01/2025",
      cvc: "123",
    },
  });
  const [cardError, setCardError] = useState("");
  let completeButtonRef = useRef(null);

  const resetState = () => {
    // Add a delay to let the modal disappear before clearing the form
    setTimeout(() => {
      reset();
      setCardError("");
    }, 500);
  };

  type IOnSubmit = {
    number: string;
    expirationDate: string;
    cvc: string;
  };
  const onSubmit = async (data: IOnSubmit) => {
    setCardError("");

    const [month, year] = data.expirationDate.split("/");

    if (!month || month.length !== 2 || !year || year.length !== 4) {
      setCardError("Invalid expiration date.");
      return;
    }

    if (data.cvc.length !== 3 && data.cvc.length !== 4) {
      setCardError("Invalid security code.");
      return;
    }

    const response = await createBilling.mutateAsync({
      cardNumber: data.number,
      cardExpirationMonth: month,
      cardExpirationYear: year,
      cardCVC: data.cvc,
    });

    if (!response.error) {
      paymentModal.close();
      resetState();
      return;
    }

    switch (response.error) {
      case errors.INVALID_CARD_NUMBER:
        setCardError("Invalid card number.");
        break;
      case errors.CUSTOMER_ALREADY_EXISTS:
        setCardError("You've already upgraded your account.");
        break;
      default: // no-op
    }
  };

  return (
    <Transition.Root show={paymentModal.isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={() => {
          paymentModal.close();
          resetState();
        }}
        initialFocus={completeButtonRef}
      >
        <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="grid grid-cols-12 gap-y-6 gap-x-4">
                  <div className="col-span-full">
                    <label
                      htmlFor="card-number"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Card number
                    </label>

                    <div className="mt-1">
                      <input
                        type="text"
                        id="card-number"
                        autoComplete="cc-number"
                        className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        {...register("number", { required: true })}
                      />
                    </div>
                  </div>

                  <div className="col-span-8 sm:col-span-9">
                    <label
                      htmlFor="expiration-date"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Expiration date (MM/YYYY)
                    </label>

                    <div className="mt-1">
                      <input
                        type="text"
                        id="expiration-date"
                        autoComplete="cc-exp"
                        className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        {...register("expirationDate", { required: true })}
                      />
                    </div>
                  </div>

                  <div className="col-span-4 sm:col-span-3">
                    <label htmlFor="cvc" className="block text-sm font-medium text-gray-700">
                      CVC
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        id="cvc"
                        autoComplete="csc"
                        className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        {...register("cvc", { required: true })}
                      />
                    </div>
                  </div>
                </div>

                {cardError ? (
                  <div className="mt-4">
                    <Error>{cardError}</Error>
                  </div>
                ) : null}

                <button
                  type="submit"
                  className="w-full px-4 py-2 mt-6 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Upgrade Now
                </button>

                <p className="flex justify-center mt-6 text-sm font-medium text-gray-500">
                  <LockClosedIcon className="w-5 h-5 text-gray-400 mr-1.5" aria-hidden="true" />
                  We never store your payment information.
                </p>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
