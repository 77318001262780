import env from "@/utils/env";
import mixpanel from "mixpanel-browser";

const methods = {
  track(eventName: string, eventData?: any) {
    if (env.isDev()) {
      console.log(`[Mixpanel] ${eventName}`, eventData);
      return;
    }

    // NOTE: tracking only works after the library has been initialized, and
    // it seems like it needs to be initialized every single time. This is strange,
    // but it works, and it doesn't appear to have a concerning performance impact.
    mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN!, {
      loaded: function (mp) {
        mp.track(eventName, eventData);
      },
    });
  },
};

export default methods;
